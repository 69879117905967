<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :tooltip="IsDashboard ? {} : tooltip"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.chart.x"
          :yName="Chart.DataProperties.chart.y"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.diary.x"
          :yName="Chart.DataProperties.diary.y"
          :marker="marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "../../../../../Logic/Backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  DateTime,
  Tooltip,
  StripLine,
  ColumnSeries,
} from "@syncfusion/ej2-vue-charts";
import { HealthPlanLogic } from "../../../../../Logic/Backend/health-plan.js";
import { ChartUiLogic } from "@/Logic/Ui/chart-ui";
import { ChartBackendLogic } from "../../../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  name: "ExerciseSteps",
  components: {
    IntervalManager,
  },
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard ? null : this.$t("module.charts.steptsCount"),
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        header: this.$t("module.charts." + this.Chart.Name),
        format: "${point.x} : <b>${point.y}</b>" + this.$t("module.charts.step"),
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
  },
  provide: {
    chart: [DateTime, Tooltip, StripLine, ColumnSeries],
  },
  methods: {
    //intervallum csere
    async intervalChanged(currentDate, interval) {
      //aktuális dátum tárolás
      this.currentDate = currentDate;
      //aktuális intervallum tárolása
      this.interval = interval;
      //intervallum napi?
      if (interval == this.$enums.ChartInterval.Day.Value) {
        //igen, chart típus napló
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        //nem, kapott chart típus mentés
        this.chartType = this.ChartType;
      }
      //intervallum beállítása
      this.setInterval();
      //adatok betöltése
      await this.getData();
    },
    //intevallum beállítása
    setInterval() {
      //intervallum beállítás objektum generálás
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      //intervallum objektum tárolása
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
    },
    //adat kérés
    async getData() {
      //chart típus chart?
      if (this.chartType == this.$enums.DiagramType.chart) {
        //igen, chart adatok betöltés
        await this.getChartData();
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        //nem, napló típus
        //napló adatok betöltés
        await this.getDiaryData();
      }
      //terv betöltése
      await this.getPlan();
    },
    //terv lista kérés
    async getPlan() {
      //van beteg id?
      if (this.PatientId) {
        //beteg terv adatok betöltése
        await this.getPatientPlanData();
      } else {
        //saját terv adatok betöltése
        await this.getPlanData();
      }
    },
    //beteg terv adatok kérés
    async getPatientPlanData() {
      //console.log("Patient ExerciseSteps Plan");
    },
    //saját terv adatok kérés
    async getPlanData() {
      //kérés indítása
      let getPlanDataResponse = await HealthPlanLogic.getHealthPlanList(
        this.Chart.DisplayPlan.planType,
        {
          Status: this.$enums.HealthGoalStatus.InProgress.Value,
          Type: this.Chart.DisplayPlan.planModuleType,
        }
      );
      //sikeres kérés?
      if (!getPlanDataResponse.Code) {
        //kapott lista nem nulla elemű?
        if (getPlanDataResponse.length > 0) {
          //nem, terv érték tárolása
          var planValue = getPlanDataResponse[0].TargetValue;
          //trev megjelenítő tároló feltöltése
          Vue.set(this.yAxis, "stripLines", [
            {
              start: planValue,
              end: 10000000000000000,
              isSegmented: true,
              visible: true,
              zIndex: "Behind",
              opacity: 0.25,
              color: "green",
            },
          ]);

          // TODO: kell-e több cél egyszerre megjeleníteni?
          /* var strinpLines = [];
          getPlanDataResponse.forEach((p) => {
            strinpLines.push({
              start: p.TargetValue,
              end: 10000000000000000,
              isSegmented: true,
              visible: true,
              zIndex: "Behind",
              opacity: 0.25,
              color: "green",
            })
          });
          //trev megjelenítő tároló feltöltése
          Vue.set(this.yAxis, "stripLines", strinpLines); */
        }
      } else {
        this.$bvToast.toast(getPlanDataResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //TODO: alkalmazni (mostani chart nem frissül megfelelően)
    checkPlanInDisplayInterval(plan) {
      const displayMin = this.xAxis.minimum;
      const displayMax = this.xAxis.maximum;
      const planStart = plan.StartDate;
      const planTarget = plan.TargetDate;
      return (
        (moment(displayMin).isSameOrBefore(planStart, "days") &&
          moment(displayMax).isSameOrAfter(planStart, "days")) ||
        (moment(displayMin).isSameOrBefore(planTarget, "days") &&
          moment(displayMax).isSameOrAfter(planTarget, "days"))
      );
    },
    //chart adat kérés
    async getChartData() {
      //kérés válasz tároló
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //beteg chart adat kérés indítás
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //saját chart adat kérés indítás
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott lista tárolása
        this.chartData = responseChartData;
        //üres mezők feltöltése
        this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
          [this.Chart.DataProperties.chart.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart intervallum paraméterek megadása
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //napló adatok kérés
    async getDiaryData() {
      //kérés válasz tároló
      var responseChartData = null;
      //van beteg?
      if (this.PatientId) {
        //van, beteg napló adatok kérés
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        //nem, saját napló adatok kérés
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott adat lista tárolása
        this.chartData = responseChartData;
        //üres mezők feltöltése
        this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
          [this.Chart.DataProperties.diary.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //napló intervallum paraméterek megadása
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    //üresmező feltöltése
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //chart típus tárolása
    this.chartType = this.ChartType;
    //intervallum tárolása
    this.interval = this.Interval;
    //intervallum beéllítása
    this.setInterval();
    //adatok betöltése
    await this.getData();
  },
};
</script>